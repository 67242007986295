import { last } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { MenuItem } from 'react-bootstrap';
import ItemList, { ActionButtonTypes } from 'src/scripts/components/ItemList/ItemList';
import ItemListHeader from 'src/scripts/components/ItemList/ItemListHeader';
import { BulkActionButtonTypes } from 'src/scripts/components/BulkAction/BulkActionBar';
import * as pagination from 'src/scripts/lib/pagination';
import { getAllIngestJobs, deleteIngestJob } from 'src/scripts/actions/ingestJobs';
import NavDropdownMenu from 'src/scripts/components/NavDropdownMenu';
import ModalItem from 'src/scripts/components/ModalItem';
import ConfirmationDialog from 'src/scripts/components/ConfirmationDialog';
import { isDeleteEnabled, states } from 'src/scripts/lib/ingestJobWorkflowActionsProvider';
import VideoListNavigation from 'src/scripts/components/Video/VideoListNavigation';
import { TV_PATH } from 'src/scripts/lib/accessController';
import { INGEST_JOB } from 'src/scripts/lib/modelNames';
import { resetItemList } from 'src/scripts/actions/itemList';
import VideoAutoQc from '../../lib/videoAutoQc';
import { FILTER_TYPES } from '../ItemList/ItemListHeader';

const { VIEW, DELETE } = BulkActionButtonTypes;
const { CLEAR } = ActionButtonTypes;

export class IngestJobList extends React.Component {
  constructor() {
    super();
    this.getAllIngestJobs = this.getAllIngestJobs.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.getAutoQcStatus = this.getAutoQcStatus.bind(this);
  }

  componentDidUpdate(prevProps) {
    this.loadData(prevProps);
  }

  getAllIngestJobs(params = {}) {
    const offset =
      typeof params.offset !== 'undefined' ? params.offset : pagination.calculateOffset(this.props.page);
    const sort = typeof params.sort !== 'undefined' ? params.sort : this.props.sort;
    const search = params.search ? params.search : null;
    return this.props.getAllIngestJobs({
      offset,
      limit: pagination.pageSize,
      sort,
      search,
      filters: params.filters || this.props.filters,
    });
  }

  getViewIngestJobLink(videoItem) {
    return `#/${TV_PATH}/videos/ingestJobs/${videoItem.id}`;
  }

  getCaptionsStatus(item) {
    if (!item.hasCaptions) return '';
    return <i className="fa fa-cc fa-lg" aria-hidden="true"></i>;
  }

  getActionList(videoItem) {
    return (
      <NavDropdownMenu
        component="div"
        buttonClass="btn btn-default"
        title={<i className="fa fa-ellipsis-v"></i>}
        id={`season-item-button-${videoItem.id}`}
      >
        <MenuItem href={this.getViewIngestJobLink(videoItem)}>View Ingest Job</MenuItem>
        {isDeleteEnabled(videoItem.state) ? (
          <ModalItem
            title="Delete Ingest Job"
            form={
              <ConfirmationDialog
                invitation={`Clicking Delete will permanently delete "${videoItem.name}" ingest job and any associated video. Are you sure you wish to proceed?`}
                ok={this.props.deleteIngestJob.bind(this, videoItem.id)}
                buttonClass="danger"
                buttonLabel="Delete"
              />
            }
          />
        ) : (
          ''
        )}
      </NavDropdownMenu>
    );
  }

  getAutoQcStatus({ video }) {
    const lastVideoAutoQc = last(video.videoAutoQcs);
    if (!lastVideoAutoQc) {
      return '';
    }

    const videoAutoQc = new VideoAutoQc(lastVideoAutoQc);
    return <span style={{ color: videoAutoQc.getStatusColour() }}>{videoAutoQc.getStatusLabel()}</span>;
  }

  clearFilters() {
    this.props.resetItemList();
    return this.props.getAllIngestJobs();
  }

  loadData(prevProps) {
    const previousPage = prevProps && prevProps.page;
    if (previousPage === this.props.page) {
      return;
    }
    this.getAllIngestJobs();
  }

  render() {
    const list = {
      pageData: this.props.list,
      totalCount: this.props.totalCount,
      pagination: {
        pageSize: pagination.pageSize,
        activePage: pagination.calculateActivePage(this.props.offset),
      },
    };
    return (
      <div id="video-list">
        <div className="mi9-breadcrumb clearfix">
          <VideoListNavigation currentVideoType="ingestJobs" refresh={this.getAllIngestJobs} />
        </div>
        <ItemList
          id="list-ingest-job"
          list={list}
          getItems={this.getAllIngestJobs}
          modelName={INGEST_JOB}
          filters={this.props.filters}
          clearFilters={this.clearFilters}
          bulkActionButtons={[VIEW, DELETE]}
          actionButtons={[CLEAR]}
          hasSearch
        >
          <ItemListHeader
            sort
            label="Date Created"
            dataField="createdAt"
            dataType="date"
            filter={{ type: FILTER_TYPES.DATE, alignLeft: true }}
          />
          <ItemListHeader filter sort label="Material Key" dataField="materialKey" />
          <ItemListHeader label="VMS Ref ID" dataField="video.referenceId" />
          <ItemListHeader label="Brightcove ID" dataField="video.brightcoveId" />
          <ItemListHeader
            sort
            filter
            label="Name"
            dataField="name"
            dataFormat={(item) => {
              return <a href={this.getViewIngestJobLink(item)}>{item.name}</a>;
            }}
          />
          <ItemListHeader sort label="Episode Number" dataField="number" />
          <ItemListHeader sort label="Season" dataField="seasonName" />
          <ItemListHeader
            sort
            label="Start Date"
            dataType="date"
            dataField="availability"
            filter={{ type: FILTER_TYPES.DATE, alignLeft: true }}
          />
          <ItemListHeader sort filter label="Series" dataField="tvSeriesName" />
          <ItemListHeader label="Video Status" dataFormat={(item) => item.video.status} dataType="humanize" />
          <ItemListHeader label={'Auto QC Status'} dataFormat={this.getAutoQcStatus} />
          <ItemListHeader
            label="Closed Captions"
            dataField="hasCaptions"
            dataFormat={(item) => {
              return this.getCaptionsStatus(item);
            }}
          />
          <ItemListHeader
            label="Status"
            dataField="state"
            dataType="humanize"
            filter={{ type: FILTER_TYPES.DROPDOWN, options: Object.keys(states) }}
          />
          <ItemListHeader label="Action" dataFormat={(item) => this.getActionList(item)} />
        </ItemList>
      </div>
    );
  }
}

IngestJobList.propTypes = {
  page: PropTypes.number,
  history: PropTypes.object,
  getAllIngestJobs: PropTypes.func,
  deleteIngestJob: PropTypes.func,
  resetItemList: PropTypes.func,
  list: PropTypes.array,
  totalCount: PropTypes.number,
  offset: PropTypes.number,
  filters: PropTypes.object,
  sort: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    list: state.ingestJobs.list,
    totalCount: state.ingestJobs.totalCount,
    offset: state.ingestJobs.offset,
    sort: state.itemList.sort,
    filters: state.itemList.filters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteIngestJob: (ingestJobId) => dispatch(deleteIngestJob(ingestJobId)),
    getAllIngestJobs: (pageData) => dispatch(getAllIngestJobs(pageData)),
    resetItemList: () => dispatch(resetItemList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IngestJobList);
