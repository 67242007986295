import React from 'react';
import { Panel, Input } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as liveEventsVmsApi from '../../../actions/apiWrappers/vmsApi/common/liveEvents';

class TrackingPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oztamPublisherIds: [],
    };
  }

  componentDidMount() {
    this.fetchOztamPublisherIds();
  }

  onOztamPublisherIdChange = (event) => {
    this.props.onOztamPublisherIdChange(event.target.value === 'unselected' ? null : event.target.value);
  };

  fetchOztamPublisherIds = async () => {
    const oztamPublisherIds = await liveEventsVmsApi
      .getOztamPublisherIdsForTvEvents()
      .then((res) => res.map((each) => ({ label: each.oztamChannelName, value: each.id })));
    this.setState({ oztamPublisherIds });
  };

  render() {
    return (
      <Panel bsStyle="primary" header="Tracking">
        {/* Do Not Remove id attribute used by Plugin Tennis Match Feed*/}
        <Input
          type="select"
          ref="liveEventOztamPublisherId"
          label="Oztam Publisher ID"
          id="oztam-publisher-id-select"
          defaultValue="default"
          labelClassName="required"
          value={this.props.liveEvent && this.props.liveEvent.oztamPublisherId}
          onChange={this.onOztamPublisherIdChange}
          bsStyle={
            this.props.validationErrors && this.props.validationErrors.oztamPublisherId ? 'error' : null
          }
          data-pw="oztam-publisher-select"
        >
          <option value="unselected">Select an oztam publisher ID</option>
          {this.state.oztamPublisherIds.map((option) => {
            return (
              <option key={option.label} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </Input>
      </Panel>
    );
  }
}

TrackingPanel.propTypes = {
  liveEvent: PropTypes.object,
  validationErrors: PropTypes.object,
  onOztamPublisherIdChange: PropTypes.func,
};

export default TrackingPanel;
