import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as pagination from 'src/scripts/lib/pagination';
import ItemList, { ActionButtonTypes } from 'src/scripts/components/ItemList/ItemList';
import VideoListNavigation from 'src/scripts/components/Video/VideoListNavigation';
import ItemListHeader from 'src/scripts/components/ItemList/ItemListHeader';
import { BulkActionButtonTypes } from 'src/scripts/components/BulkAction/BulkActionBar';
import { states, networkStates } from 'src/scripts/lib/workflowActionsProvider';
import ContextNavigation from 'src/scripts/components/ContextNavigation';
import { TV_PATH, NETWORK_PATH } from 'src/scripts/lib/accessController';
import { getThumbnailImageUrl } from 'src/scripts/lib/imageUtil';
import { EPISODE, CLIP, NETWORK_CLIP } from 'src/scripts/lib/modelNames';
import FileDropZone from 'src/scripts/components/FileDropZone';
import EpisodeFormLoader from 'src/scripts/components/Episode/EpisodeFormLoader';
import ModalItem from 'src/scripts/components/ModalItem';
import NotesWithTooltip from '../NotesWithTooltip';
import { FILTER_TYPES } from '../ItemList/ItemListHeader';

const { VIEW, EDIT, RESCHEDULE, RETRANSCODE, EXPIRE, ARCHIVE, DELETE } = BulkActionButtonTypes;
const { EXPORT, CLEAR } = ActionButtonTypes;

const ConditionalFileDropZone = ({ modelName, children, ...props }) => {
  if (modelName === 'episodes') {
    return <FileDropZone {...props}>{children}</FileDropZone>;
  }
  return <div>{children}</div>;
};
ConditionalFileDropZone.propTypes = {
  modelName: PropTypes.string,
  children: PropTypes.any,
};

export class VideoList extends React.Component {
  constructor() {
    super();
    this.getItems = this.getItems.bind(this);
    this.state = {
      episodeFormOpen: false,
      episodeFormSeedData: {},
    };
  }

  componentDidUpdate(prevProps) {
    this.loadData(prevProps);
  }

  getItems(params = {}) {
    const offset =
      typeof params.offset !== 'undefined' ? params.offset : pagination.calculateOffset(this.props.page);
    this.props.getItems({
      offset,
      limit: pagination.pageSize,
      sort: params.sort || this.props.sort,
      filters: params.filters || this.props.filters,
    });
  }

  getCaptionsStatus(episodeItem) {
    if (!episodeItem.hasCaptions) return '';
    return <i className="fa fa-cc fa-lg" aria-hidden="true"></i>;
  }

  getActionButtonsForModel(modelName) {
    if (modelName === EPISODE || modelName === CLIP || modelName === NETWORK_CLIP) {
      return [EXPORT, CLEAR];
    }
    return [];
  }

  loadData(prevProps) {
    const previousPage = prevProps && prevProps.page;
    if (previousPage === this.props.page) {
      return;
    }
    this.getItems();
  }

  onFileDropped = (file) => {
    this.setState({ episodeFormOpen: true, episodeFormSeedData: { video: { file } } });
  };

  render() {
    const videoList = {
      pageData: this.props.list,
      totalCount: this.props.totalCount,
      pagination: {
        pageSize: pagination.pageSize,
        activePage: pagination.calculateActivePage(this.props.offset),
      },
    };

    return (
      <ConditionalFileDropZone
        modelName={this.props.modelName}
        onDrop={this.onFileDropped}
        fileTypePrefix="video"
      >
        <ModalItem
          className="video-list-episode-modal"
          isOpen={this.state.episodeFormOpen}
          onClose={() => this.setState({ episodeFormOpen: false })}
          form={
            <EpisodeFormLoader episodeSeedData={this.state.episodeFormSeedData} isEpisodeDraggedandDropped />
          }
        />
        <div id="video-list">
          {this.props.modelName === 'clips' || this.props.modelName === 'episodes' ? (
            <VideoListNavigation currentVideoType={this.props.modelName} refresh={this.getItems} />
          ) : (
            <ContextNavigation breadcrumbList={['All Videos', 'Clips']} />
          )}
          <ItemList
            list={videoList}
            defaultSort="-updatedAt"
            defaultFilter={this.props.defaultFilter}
            getItems={this.props.getItems}
            id={this.props.id}
            modelName={this.props.modelName}
            clearFilters={this.props.clearFilters}
            filters={this.props.filters}
            bulkActionButtons={[VIEW, EDIT, RESCHEDULE, RETRANSCODE, EXPIRE, ARCHIVE, DELETE]}
            actionButtons={this.getActionButtonsForModel(this.props.modelName)}
            hasSearch={this.props.hasSearch}
          >
            <ItemListHeader
              label="Image"
              dataField="image"
              dataFormat={(item) => {
                return (
                  <span className="row-image-span">
                    <img
                      ref="img"
                      className="row-image"
                      src={getThumbnailImageUrl(item.image)}
                      alt={(item.image && item.image.alt) || ''}
                    />
                  </span>
                );
              }}
            />
            <ItemListHeader
              sort
              label="Date Updated"
              dataField="updatedAt"
              dataType="date"
              filter={{ type: FILTER_TYPES.DATE, alignLeft: true }}
            />
            {this.props.modelName === 'episodes' ? (
              <ItemListHeader filter label="Material Key" dataField="materialKey" />
            ) : null}
            <ItemListHeader filter dataField="video.referenceId" label="VMS Ref ID" />
            <ItemListHeader filter dataField="video.brightcoveId" label="Brightcove ID" />
            {this.props.modelName === 'clips' || this.props.modelName === 'episodes' ? (
              <ItemListHeader
                sort
                filter
                label="Name"
                dataField="name"
                dataFormat={(videoItem) => {
                  return (
                    <a
                      href={`#/${TV_PATH}/tv-series/${videoItem.partOfSeries.id}/seasons/${videoItem.partOfSeason.id}/${this.props.modelName}/${videoItem.id}`}
                    >
                      {videoItem.name}
                    </a>
                  );
                }}
                data-pw="item-list-header-name"
              />
            ) : (
              <ItemListHeader
                sort
                filter
                label="Name"
                dataField="name"
                dataFormat={(videoItem) => {
                  return (
                    <a
                      href={`#/network/categories/${videoItem.partOfCategory.id}/subcategories/${videoItem.partOfSubcategory.id}/clips/${videoItem.id}`}
                    >
                      {videoItem.name}
                    </a>
                  );
                }}
              />
            )}
            {this.props.modelName === 'clips' || this.props.modelName === 'episodes' ? (
              <ItemListHeader
                label="Season"
                dataField="season.name"
                dataFormat={(videoItem) => {
                  return (
                    <a
                      href={`#/${TV_PATH}/tv-series/${videoItem.partOfSeries.id}/seasons/${videoItem.partOfSeason.id}/${this.props.modelName}`}
                    >
                      {videoItem.partOfSeason.name}
                    </a>
                  );
                }}
              />
            ) : null}
            {this.props.modelName === 'clips' || this.props.modelName === 'episodes' ? (
              <ItemListHeader
                filter
                label="Series"
                dataField="tvSeries.name"
                dataFormat={(videoItem) => {
                  return (
                    <a href={`#/${TV_PATH}/tv-series/${videoItem.partOfSeries.id}/seasons`}>
                      {videoItem.partOfSeries.name}
                    </a>
                  );
                }}
              />
            ) : null}
            {this.props.modelName === 'networkClips' ? (
              <ItemListHeader
                filter
                label="Category"
                dataField="category.name"
                dataFormat={(videoItem) => {
                  return (
                    <a href={`#/${NETWORK_PATH}/categories/${videoItem.partOfCategory.id}/subcategories`}>
                      {videoItem.partOfCategory.name}
                    </a>
                  );
                }}
              />
            ) : null}
            {this.props.modelName === 'networkClips' ? (
              <ItemListHeader
                label="Subcategory"
                dataField="subcategory.name"
                dataFormat={(videoItem) => {
                  return (
                    <a
                      href={`#/${NETWORK_PATH}/categories/${videoItem.partOfSubcategory.categoryId}/subcategories/${videoItem.partOfSubcategory.id}/clips`}
                    >
                      {videoItem.partOfSubcategory.name}
                    </a>
                  );
                }}
              />
            ) : null}
            <ItemListHeader label="Duration" dataType="duration" dataField="video.duration" />
            <ItemListHeader
              sort
              label="Start Date"
              dataType="date"
              dataField="availability"
              filter={{ type: FILTER_TYPES.DATE }}
            />
            <ItemListHeader
              sort
              label="End Date"
              dataType="date"
              dataField="expiry"
              filter={{ type: FILTER_TYPES.DATE }}
            />
            {this.props.modelName === 'episodes' ? (
              <ItemListHeader
                label="Broadcast Air Date"
                dataType="date"
                dataField="broadcastAirDate"
                filter={{ type: FILTER_TYPES.DATE }}
              />
            ) : null}
            <ItemListHeader
              label="Notes"
              dataFormat={(item) => {
                const capitalizedWord =
                  this.props.modelName.charAt(0).toUpperCase() + this.props.modelName.slice(1);
                const finalWord = capitalizedWord.endsWith('s')
                  ? capitalizedWord.slice(0, -1)
                  : capitalizedWord;
                return (
                  <div className="notes-tooltip">
                    <NotesWithTooltip
                      notes={item.producerNotes}
                      emptyText={`No Notes for this ${finalWord}`}
                    />
                  </div>
                );
              }}
            />
            <ItemListHeader
              label="Video Status"
              dataFormat={(item) => item.video.status}
              dataType="humanize"
            />
            {this.props.modelName === 'episodes' ? (
              <ItemListHeader
                label="Closed Captions"
                dataField="hasCaptions"
                dataFormat={(episodeItem) => {
                  return this.getCaptionsStatus(episodeItem);
                }}
              />
            ) : null}
            {this.props.modelName === 'clips' || this.props.modelName === 'episodes' ? (
              <ItemListHeader
                label="DRM"
                dataField="video.drm"
                dataFormat={(item) => (item.video.drm ? 'Y' : 'N')}
              />
            ) : null}
            <ItemListHeader
              label="Workflow Status"
              dataField="state"
              filter={{
                type: FILTER_TYPES.DROPDOWN,
                options: this.props.modelName === 'networkClips' ? networkStates : states,
              }}
              dataType="humanize"
            />
            {this.props.modelName === 'networkClips' ? (
              <ItemListHeader
                label="Require Review"
                dataField="requireReview"
                dataFormat={(item) => (item.requireReview ? 'Y' : 'N')}
              />
            ) : null}
            {this.props.modelName === 'networkClips' ? (
              <ItemListHeader label="Import Source" dataField="importSource" />
            ) : null}
            {this.props.modelName === 'networkClips' ? (
              <ItemListHeader label="Source ID" dataField="sourceReferenceId" />
            ) : null}
          </ItemList>
        </div>
      </ConditionalFileDropZone>
    );
  }
}

VideoList.propTypes = {
  list: PropTypes.array,
  totalCount: PropTypes.number,
  offset: PropTypes.number,
  sort: PropTypes.string,
  filters: PropTypes.object,
  page: PropTypes.number,
  getItems: PropTypes.func,
  modelName: PropTypes.string,
  history: PropTypes.object,
  activeNavigationKey: PropTypes.number,
  navigationMenuTitle: PropTypes.string,
  id: PropTypes.string,
  defaultFilter: PropTypes.object,
  clearFilters: PropTypes.func,
  hasSearch: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    totalCount: state.video.totalCount,
    list: state.video.list,
    offset: state.video.offset,
    sort: state.itemList.sort,
    filters: state.itemList.filters,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    launchAddEpisodeModal: () => dispatch({}),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoList);
