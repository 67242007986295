import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ItemList, { ActionButtonTypes } from 'src/scripts/components/ItemList/ItemList';
import ItemListHeader from 'src/scripts/components/ItemList/ItemListHeader';
import { BulkActionButtonTypes } from 'src/scripts/components/BulkAction/BulkActionBar';
import * as clipActions from 'src/scripts/actions/clip';
import * as seasonActions from 'src/scripts/actions/season';
import * as breadcrumbActions from 'src/scripts/actions/breadcrumb';
import * as pagination from 'src/scripts/lib/pagination';
import { getClipWorkflowActions } from 'src/scripts/lib/workflowActionsProvider';
import ClipFormLoader from 'src/scripts/components/Clip/ClipFormLoader';
import history from 'src/scripts/lib/history';
import SeasonMediaItemNavigation from 'src/scripts/components/Season/SeasonMediaItemNavigation';
import FileDropZone from 'src/scripts/components/FileDropZone';
import ClipDropZonePromptForm from 'src/scripts/components/Clip/ClipDropZonePromptForm';
import { states } from 'src/scripts/lib/workflowActionsProvider';
import { TV_PATH } from 'src/scripts/lib/accessController';
import WorkflowActions from 'src/scripts/components/MediaItem/WorkflowActions';
import CopyButton from 'src/scripts/components/CopyButton';
import { getVideoUploadMetaData } from 'src/scripts/reducers/video';
import { videoStatusDataFormatHandler } from 'src/scripts/lib/videoStatus';
import { formatClipPropertiesToCopy } from 'src/scripts/lib/stringFormatter';
import { resetItemList } from 'src/scripts/actions/itemList';
import { CLIP } from 'src/scripts/lib/modelNames';
import { IMAGE_NOT_FOUND_URL } from 'src/scripts/constants/common';
import NotesWithTooltip from '../NotesWithTooltip';
import { FILTER_TYPES } from '../ItemList/ItemListHeader';

const { VIEW, EDIT, RESCHEDULE, BULK_EDIT, RETRANSCODE, EXPIRE, ARCHIVE, DELETE, DOWNLOAD, ASSIGN_EPISODE } =
  BulkActionButtonTypes;
const { CLEAR } = ActionButtonTypes;

export class Clip extends React.Component {
  constructor() {
    super();
    this.getClips = this.getClips.bind(this);
    this.onFileDropped = this.onFileDropped.bind(this);
    this.loadData = this.loadData.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const seasonId = Number(this.props.params.seasonId);
    const previousSeasonId = Number(prevProps.params.seasonId);
    if (prevProps.page !== this.props.page || seasonId !== previousSeasonId) {
      this.loadData();
    }
    if (
      prevProps.page !== this.props.page ||
      (this.props.savedClip && this.props.savedClip.seasonId === seasonId)
    ) {
      this.getClips();
    }
  }

  onFileDropped(file, availability) {
    if (this.props.currentSeason) {
      return this.props.saveClip(this.getDefaultClip(file, availability));
    }
    return undefined;
  }

  getDefaultClip(file, availability = new Date()) {
    return {
      name: file.name,
      seasonId: Number(this.props.params.seasonId),
      availability,
      geoBlocking: this.props.currentSeason.clipsGeoBlocked,
      watermarkEnabled: this.props.currentSeason.clipsWatermarkEnabled,
      video: {
        file,
        cuePoints: [{ time: 0 }],
      },
    };
  }

  getClips(params = {}) {
    const seasonId = Number(this.props.params.seasonId);
    const offset =
      typeof params.offset !== 'undefined' ? params.offset : pagination.calculateOffset(this.props.page);
    return this.props.get({
      seasonId,
      offset,
      limit: pagination.pageSize,
      sort: params.sort || this.props.sort,
      filters: params.filters || this.props.filters,
    });
  }

  getViewClipLink(clipItem) {
    return `#/${TV_PATH}/tv-series/${clipItem.partOfSeries.id}/seasons/${clipItem.partOfSeason.id}/clips/${clipItem.id}`;
  }

  getActionList(clipItem, rowIndex) {
    return (
      <WorkflowActions
        getWorkflowActions={getClipWorkflowActions}
        model={clipItem}
        resource="clip"
        disableWorkflow={this.props.disableWorkflow}
        viewLink={this.getViewClipLink(clipItem)}
        editForm={<ClipFormLoader clipId={Number(clipItem.id)} />}
        delete={this.props.deleteClipAndCloseConfirmation.bind(this, clipItem.id)}
        index={rowIndex}
      />
    );
  }

  loadData() {
    const tvSeriesId = Number(this.props.params.id);
    const seasonId = Number(this.props.params.seasonId);
    if (!seasonId) {
      return history.replace({
        state: null,
        pathname: `/${TV_PATH}/tv-series/${tvSeriesId}/seasons`,
        query: { page: 1 },
      });
    }
    this.props.getCurrentSeason(seasonId);
    this.props.updateTVSeriesBreadcrumb(tvSeriesId);
    return this.props.updateSeasonBreadcrumb(tvSeriesId, seasonId);
  }

  clearFilters() {
    return this.props.resetItemList();
  }

  render() {
    const itemList = {
      pageData: this.props.clips,
      totalCount: this.props.count,
      pagination: {
        pageSize: pagination.pageSize,
        activePage: pagination.calculateActivePage(this.props.offset),
      },
    };

    return (
      <FileDropZone
        dropPromptForm={ClipDropZonePromptForm}
        onDrop={this.onFileDropped}
        fileTypePrefix="video"
      >
        <SeasonMediaItemNavigation
          seasonId={this.props.params.seasonId}
          seasonName={this.props.seasonName}
          tvSeriesName={this.props.tvSeriesName}
          tvSeriesId={this.props.params.id}
          currentVideoType="clips"
        />
        <ItemList
          id="list-clip"
          list={itemList}
          getItems={this.getClips}
          modelName={CLIP}
          bulkActionButtons={[
            VIEW,
            EDIT,
            RESCHEDULE,
            BULK_EDIT,
            RETRANSCODE,
            EXPIRE,
            ARCHIVE,
            DELETE,
            DOWNLOAD,
            ASSIGN_EPISODE,
          ]}
          actionButtons={[CLEAR]}
          clearFilters={this.clearFilters}
          seasonId={this.props.params.seasonId}
        >
          <ItemListHeader
            dataFormat={(item) => {
              return (
                <span className="row-image-span">
                  <img
                    ref="img"
                    className="row-image"
                    src={(item.image && item.image.url) || IMAGE_NOT_FOUND_URL}
                    alt={(item.image && item.image.alt) || ''}
                  />
                </span>
              );
            }}
            label="Thumbnail"
          />
          <ItemListHeader
            sort
            filter
            dataField="name"
            dataFormat={(item) => {
              return <a href={this.getViewClipLink(item)}>{item.name}</a>;
            }}
            label="Name"
          />
          <ItemListHeader dataField="partOfEpisode.name" label="Episode" />
          <ItemListHeader
            dataFormat={(clipItem) => videoStatusDataFormatHandler(clipItem, this.props.videoUploadMetaData)}
            label="Video Status"
            dataType="humanize"
          />
          <ItemListHeader
            dataField="state"
            dataType="humanize"
            label="Workflow Status"
            filter={{ type: FILTER_TYPES.DROPDOWN, options: states }}
          />
          <ItemListHeader dataField="video.referenceId" label="VMS Reference ID" />
          <ItemListHeader filter dataField="video.brightcoveId" label="Brightcove ID" />
          <ItemListHeader
            sort
            filter={{ type: FILTER_TYPES.DATE }}
            dataField="availability"
            label="Start Date"
            dataType="date"
          />
          <ItemListHeader
            sort
            filter={{ type: FILTER_TYPES.DATE }}
            dataField="expiry"
            label="End Date"
            dataType="date"
          />
          <ItemListHeader
            label="Notes"
            dataFormat={(clipItem) => {
              return (
                <div className="notes-tooltip">
                  <NotesWithTooltip notes={clipItem.producerNotes} emptyText="No Notes for this Clip" />
                </div>
              );
            }}
          />
          <ItemListHeader sort dataField="createdAt" label="Date Created" dataType="date" />
          <ItemListHeader
            label="Copy to Clipboard"
            dataFormat={(clipItem) => {
              const textToCopy = formatClipPropertiesToCopy(clipItem);
              return <CopyButton textToCopy={textToCopy} />;
            }}
          />
          <ItemListHeader
            label="Actions"
            id="button-clip-actions"
            dataFormat={(clipItem, rowIndex) => {
              return this.getActionList(clipItem, rowIndex);
            }}
          />
        </ItemList>
      </FileDropZone>
    );
  }
}

Clip.propTypes = {
  seasonName: PropTypes.string,
  tvSeriesName: PropTypes.string,
  disableWorkflow: PropTypes.bool,
  params: PropTypes.object,
  page: PropTypes.number,
  count: PropTypes.number,
  offset: PropTypes.number,
  clips: PropTypes.array,
  get: PropTypes.func,
  getCurrentSeason: PropTypes.func,
  deleteClipAndCloseConfirmation: PropTypes.func,
  saveClip: PropTypes.func,
  updateTVSeriesBreadcrumb: PropTypes.func,
  updateSeasonBreadcrumb: PropTypes.func,
  sort: PropTypes.string,
  filters: PropTypes.object,
  savedClip: PropTypes.object,
  videoUploadMetaData: PropTypes.object,
  getUploadStatusByVideoId: PropTypes.func,
  currentSeason: PropTypes.object,
  resetItemList: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    errorMessage: state.clips.errorMessage,
    disableWorkflow: state.clips.disableWorkflow,
    clips: state.clips.list,
    offset: state.clips.offset,
    count: state.clips.count,
    tvSeriesName: state.breadcrumb.tvSeriesName,
    seasonName: state.breadcrumb.seasonName,
    sort: state.itemList.sort,
    filters: state.itemList.filters,
    savedClip: state.clips.savedClip,
    videoUploadMetaData: getVideoUploadMetaData(state),
    currentSeason: state.currentSeason,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    get: (pageData) => dispatch(clipActions.get(pageData)),
    getCurrentSeason: (seasonId) => dispatch(seasonActions.getCurrentSeason(seasonId)),
    deleteClipAndCloseConfirmation: (clipId) => dispatch(clipActions.deleteClipAndCloseConfirmation(clipId)),
    saveClip: (clipData) => dispatch(clipActions.saveClip(clipData)),
    updateTVSeriesBreadcrumb: (tvSeriesId) =>
      dispatch(breadcrumbActions.updateTVSeriesBreadcrumb(tvSeriesId)),
    updateSeasonBreadcrumb: (tvSeriesId, seasonId) =>
      dispatch(breadcrumbActions.updateSeasonBreadcrumb(tvSeriesId, seasonId)),
    resetItemList: () => dispatch(resetItemList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Clip);
