import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import ItemList, { ActionButtonTypes } from 'src/scripts/components/ItemList/ItemList';
import { BulkActionButtonTypes } from 'src/scripts/components/BulkAction/BulkActionBar';
import { getEpisodes, deleteEpisodeAndCloseConfirmation, saveEpisode } from 'src/scripts/actions/episode';
import { updateTVSeriesBreadcrumb, updateSeasonBreadcrumb } from 'src/scripts/actions/breadcrumb';
import ItemListHeader from 'src/scripts/components/ItemList/ItemListHeader';
import * as pagination from 'src/scripts/lib/pagination';
import { getEpisodeWorkflowActions } from 'src/scripts/lib/workflowActionsProvider';
import EpisodeFormLoader from 'src/scripts/components/Episode/EpisodeFormLoader';
import history from 'src/scripts/lib/history';
import SeasonMediaItemNavigation from 'src/scripts/components/Season/SeasonMediaItemNavigation';
import { states } from 'src/scripts/lib/workflowActionsProvider';
import { TV_PATH } from 'src/scripts/lib/accessController';
import WorkflowActions from 'src/scripts/components/MediaItem/WorkflowActions';
import { getVideoUploadMetaData } from 'src/scripts/reducers/video';
import { videoStatusDataFormatHandler } from 'src/scripts/lib/videoStatus';
import { EPISODE } from 'src/scripts/lib/modelNames';
import { resetItemList } from 'src/scripts/actions/itemList';
import { IMAGE_NOT_FOUND_URL } from 'src/scripts/constants/common';
import NotesWithTooltip from '../NotesWithTooltip';
import { FILTER_TYPES } from '../ItemList/ItemListHeader';

const { VIEW, EDIT, RESCHEDULE, BULK_EDIT, RETRANSCODE, EXPIRE, ARCHIVE, DELETE, DOWNLOAD } =
  BulkActionButtonTypes;
const { CLEAR } = ActionButtonTypes;

export class Episode extends React.Component {
  constructor(props) {
    super(props);
    this.getEpisodes = this.getEpisodes.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    this.loadData(prevProps);
    if (
      prevProps.page !== this.props.page ||
      (this.props.savedEpisode && this.props.savedEpisode.seasonId === Number(this.props.params.seasonId))
    ) {
      this.getEpisodes();
    }
  }

  getEpisodes(params = {}) {
    const seasonId = Number(this.props.params.seasonId);
    const offset =
      typeof params.offset !== 'undefined' ? params.offset : pagination.calculateOffset(this.props.page);
    return this.props.getEpisodes({
      seasonId,
      offset,
      limit: pagination.pageSize,
      sort: params.sort || this.props.sort,
      filters: params.filters || this.props.filters,
    });
  }

  getViewEpisodeLink(episodeItem) {
    return `#/${TV_PATH}/tv-series/${episodeItem.partOfSeries.id}/seasons/${episodeItem.partOfSeason.id}/episodes/${episodeItem.id}`;
  }

  getActionList(episodeItem, rowIndex) {
    return (
      <WorkflowActions
        getWorkflowActions={getEpisodeWorkflowActions}
        model={episodeItem}
        resource="episode"
        disableWorkflow={this.props.disableWorkflow}
        viewLink={this.getViewEpisodeLink(episodeItem)}
        editForm={<EpisodeFormLoader episodeId={Number(episodeItem.id)} />}
        delete={this.props.deleteEpisodeAndCloseConfirmation.bind(this, episodeItem.id)}
        index={rowIndex}
      />
    );
  }

  getCaptionsStatus(episodeItem) {
    if (!episodeItem.hasCaptions) return '';
    return <i className="fa fa-cc fa-lg" aria-hidden="true"></i>;
  }

  clearFilters() {
    return this.props.resetItemList();
  }

  loadData(prevProps) {
    const previousSeasonId = prevProps && Number(prevProps.params.seasonId);
    const previousPage = prevProps && prevProps.page;

    const tvSeriesId = Number(this.props.params.id);
    const seasonId = Number(this.props.params.seasonId);
    if (previousPage === this.props.page && seasonId === previousSeasonId) {
      return null;
    }

    if (!tvSeriesId) {
      return history.replace({ state: null, pathname: `/${TV_PATH}/tv-series`, query: { page: 1 } });
    }
    if (!seasonId) {
      return history.replace({
        state: null,
        pathname: `/${TV_PATH}/tv-series/${tvSeriesId}/seasons`,
        query: { page: 1 },
      });
    }

    this.props.updateTVSeriesBreadcrumb(tvSeriesId);
    return this.props.updateSeasonBreadcrumb(tvSeriesId, seasonId);
  }

  render() {
    const list = {
      pageData: this.props.list,
      totalCount: this.props.count,
      pagination: {
        pageSize: pagination.pageSize,
        activePage: pagination.calculateActivePage(this.props.offset),
      },
    };

    return (
      <div>
        <SeasonMediaItemNavigation
          seasonId={this.props.params.seasonId}
          seasonName={this.props.seasonName}
          tvSeriesName={this.props.tvSeriesName}
          tvSeriesId={this.props.params.id}
          currentVideoType="episodes"
        />
        <ItemList
          id="list-episode"
          list={list}
          getItems={this.getEpisodes}
          defaultSort="-number"
          modelName={EPISODE}
          bulkActionButtons={[
            VIEW,
            EDIT,
            RESCHEDULE,
            BULK_EDIT,
            RETRANSCODE,
            EXPIRE,
            ARCHIVE,
            DELETE,
            DOWNLOAD,
          ]}
          actionButtons={[CLEAR]}
          clearFilters={this.clearFilters}
        >
          <ItemListHeader
            label="Thumbnail"
            dataField="image"
            dataFormat={(episodeItem) => {
              return (
                <span className="row-image-span">
                  <img
                    ref="img"
                    className="row-image"
                    src={(episodeItem.image && episodeItem.image.url) || IMAGE_NOT_FOUND_URL}
                    alt={(episodeItem.image && episodeItem.image.alt) || ''}
                  />
                </span>
              );
            }}
          />
          <ItemListHeader
            sort
            filter
            label="Name"
            dataField="name"
            dataFormat={(episodeItem) => {
              return <a href={this.getViewEpisodeLink(episodeItem)}>{episodeItem.name}</a>;
            }}
          />
          <ItemListHeader sort label="Episode No." dataField="number" />
          <ItemListHeader label={'Classification'} dataField={'classificationCode'} />
          <ItemListHeader filter dataField="video.brightcoveId" label="Brightcove ID" />
          <ItemListHeader
            label="Video Status"
            dataType="humanize"
            dataField="number2"
            dataFormat={(episodeItem) =>
              videoStatusDataFormatHandler(episodeItem, this.props.videoUploadMetaData)
            }
          />
          <ItemListHeader
            filter={{ type: FILTER_TYPES.DROPDOWN, options: states }}
            label="Workflow Status"
            dataField="state"
            dataType="humanize"
          />
          <ItemListHeader
            sort
            filter={{ type: FILTER_TYPES.DATE }}
            dataField="availability"
            label="Start Date"
            dataType="date"
          />
          <ItemListHeader
            sort
            filter={{ type: FILTER_TYPES.DATE }}
            dataField="expiry"
            label="End Date"
            dataType="date"
          />
          <ItemListHeader
            label="Broadcast Air Date"
            dataType="date"
            dataField="broadcastAirDate"
            filter={{ type: FILTER_TYPES.DATE }}
          />
          <ItemListHeader
            label="Notes"
            dataFormat={(episodeItem) => {
              return (
                <div className="notes-tooltip">
                  <NotesWithTooltip notes={episodeItem.producerNotes} emptyText="No Notes for this Episode" />
                </div>
              );
            }}
          />
          <ItemListHeader
            label="DRM"
            dataFormat={(episodeItem) => {
              return episodeItem.video.drm ? '✔' : '✖';
            }}
          />
          <ItemListHeader sort label="Date Created" dataField="createdAt" dataType="date" />
          <ItemListHeader
            label="Closed Captions"
            dataField="hasCaptions"
            dataFormat={(episodeItem) => {
              return this.getCaptionsStatus(episodeItem);
            }}
          />
          <ItemListHeader
            label="Actions"
            id="button-episode-actions"
            dataFormat={(episodeItem, rowIndex) => {
              return this.getActionList(episodeItem, rowIndex);
            }}
          />
        </ItemList>
      </div>
    );
  }
}

Episode.propTypes = {
  disableWorkflow: PropTypes.bool,
  list: PropTypes.array,
  offset: PropTypes.number,
  count: PropTypes.number,
  params: PropTypes.object,
  page: PropTypes.number,
  errorMessage: PropTypes.string,
  tvSeriesName: PropTypes.string,
  seasonName: PropTypes.string,
  getEpisodes: PropTypes.func,
  deleteEpisodeAndCloseConfirmation: PropTypes.func,
  updateTVSeriesBreadcrumb: PropTypes.func,
  updateSeasonBreadcrumb: PropTypes.func,
  sort: PropTypes.string,
  filters: PropTypes.object,
  savedEpisode: PropTypes.object,
  saveEpisode: PropTypes.func,
  videoUploadMetaData: PropTypes.object,
  getUploadStatusByVideoId: PropTypes.func,
  resetItemList: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    tvSeriesName: state.breadcrumb.tvSeriesName,
    seasonName: state.breadcrumb.seasonName,
    disableWorkflow: state.episodes.disableWorkflow,
    list: state.episodes.list,
    offset: state.episodes.offset,
    count: state.episodes.count,
    errorMessage: state.episodes.errorMessage,
    sort: state.itemList.sort,
    filters: state.itemList.filters,
    savedEpisode: state.episodes.savedEpisode,
    videoUploadMetaData: getVideoUploadMetaData(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getEpisodes: (pageData) => dispatch(getEpisodes(pageData)),
    deleteEpisodeAndCloseConfirmation: (episodeId) => dispatch(deleteEpisodeAndCloseConfirmation(episodeId)),
    updateTVSeriesBreadcrumb: (tvSeriesId) => dispatch(updateTVSeriesBreadcrumb(tvSeriesId)),
    updateSeasonBreadcrumb: (tvSeriesId, seasonId) => dispatch(updateSeasonBreadcrumb(tvSeriesId, seasonId)),
    saveEpisode: (episode) => dispatch(saveEpisode(episode)),
    resetItemList: () => dispatch(resetItemList()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Episode);
